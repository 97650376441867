import React, { useState, useRef } from "react"

// Components
import SidebarLayout from "../../../layouts/SidebarLayout"
import Section from "../../../components/section/Section"
import Seo from "../../../components/seo"
import ogImage from "../../../images/seo/footers/footer.png"
import ContentNavigation from "../../../components/scrollspy"

// Libraries / Components for Preview
import PreviewBlock from "../../../components/preview"

import FooterHeroComponent from "../../../library/components/footer/react/_preview/FooterHeroComponent"

// Preview
import FooterThreeColsPreview from "../../../library/components/footer/react/_preview/three-cols"
import FooterFourColsPreview from "../../../library/components/footer/react/_preview/four-cols"
import FooterThreeColsSubFooterPreview from "../../../library/components/footer/react/_preview/three-cols-sub-footer"
import FooterFourColsSubFooterPreview from "../../../library/components/footer/react/_preview/four-cols-sub-footer"
import FooterFiveColsLogoPreview from "../../../library/components/footer/react/_preview/five-cols-logo"
import FooterFiveColsLogoSubFooterPreview from "../../../library/components/footer/react/_preview/five-cols-logo-sub-footer"
import FooterDarkThemePreview from "../../../library/components/footer/react/_preview/dark-theme"
import FooterPrimaryLightThemePreview from "../../../library/components/footer/react/_preview/primary-light-theme"
import FooterPrimaryDarkThemePreview from "../../../library/components/footer/react/_preview/primary-dark-theme"

// All
const FooterThreeColsJsx = require("!!raw-loader!../../../library/components/footer/react/three-cols.jsx")
const FooterThreeColsHTML = require("!!raw-loader!../../../library/components/footer/html/three-cols.html")

const FooterFourColsJsx = require("!!raw-loader!../../../library/components/footer/react/four-cols.jsx")
const FooterFourColsHTML = require("!!raw-loader!../../../library/components/footer/html/four-cols.html")

const FooterThreeColsSubFooterJsx = require("!!raw-loader!../../../library/components/footer/react/three-cols-sub-footer.jsx")
const FooterThreeColsSubFooterHTML = require("!!raw-loader!../../../library/components/footer/html/three-cols-sub-footer.html")

const FooterFourColsSubFooterJsx = require("!!raw-loader!../../../library/components/footer/react/four-cols-sub-footer.jsx")
const FooterFourColsSubFooterHTML = require("!!raw-loader!../../../library/components/footer/html/four-cols-sub-footer.html")

const FooterFiveColsLogoJsx = require("!!raw-loader!../../../library/components/footer/react/five-cols-logo.jsx")
const FooterFiveColsLogoHTML = require("!!raw-loader!../../../library/components/footer/html/five-cols-logo.html")

const FooterFiveColsLogoSubFooterJsx = require("!!raw-loader!../../../library/components/footer/react/five-cols-logo-sub-footer.jsx")
const FooterFiveColsLogoSubFooterHTML = require("!!raw-loader!../../../library/components/footer/html/five-cols-logo-sub-footer.html")

const FooterDarkThemeJsx = require("!!raw-loader!../../../library/components/footer/react/dark-theme.jsx")
const FooterDarkThemeHTML = require("!!raw-loader!../../../library/components/footer/html/dark-theme.html")

const FooterPrimaryDarkThemeJsx = require("!!raw-loader!../../../library/components/footer/react/primary-dark-theme.jsx")
const FooterPrimaryDarkThemeHTML = require("!!raw-loader!../../../library/components/footer/html/primary-dark-theme.html")

const FooterPrimaryLightThemeJsx = require("!!raw-loader!../../../library/components/footer/react/primary-light-theme.jsx")
const FooterPrimaryLightThemeHTML = require("!!raw-loader!../../../library/components/footer/html/primary-light-theme.html")

const FootersPage = () => {
  const [activeTabs, setActiveTabs] = useState([
    {
      component_name: "FooterThreeCols",
      title: "Three columns footer",
      active_tab: 1,
    },
    {
      component_name: "FooterFourCols",
      title: "Four columns",
      active_tab: 1,
    },
    {
      component_name: "FooterThreeColsSubFooter",
      title: "Three columns & sub-footer",
      active_tab: 1,
    },
    {
      component_name: "FooterFourColsSubFooter",
      title: "Four columns & sub-footer",
      active_tab: 1,
    },
    {
      component_name: "FooterFiveColsLogo",
      title: "Five columns, logo",
      active_tab: 1,
    },
    {
      component_name: "FooterFiveColsLogoSubFooter",
      title: "Five columns, logo & sub-footer",
      active_tab: 1,
    },
    {
      component_name: "FooterDarkTheme",
      title: "Dark theme",
      active_tab: 1,
    },
    {
      component_name: "FooterPrimaryDarkTheme",
      title: "Dark primary theme",
      active_tab: 1,
    },
    {
      component_name: "FooterPrimaryLightTheme",
      title: "Light primary theme",
      active_tab: 1,
    },
  ])

  const wrapperRef = useRef(null)

  const getActiveTabs = (id, tab) => {
    let updatedActiveTabs = activeTabs.map(activeTab => {
      if (activeTab.component_name === id) {
        return { ...activeTab, active_tab: tab }
      }
      return activeTab
    })
    setActiveTabs(updatedActiveTabs)
  }

  return (
    <SidebarLayout>
      <Seo
        title="Tailwind CSS Footers - WindUI Component Library"
        ogtitle="Tailwind CSS Footers - WindUI Component Library"
        description="Footer component, is the section at the very bottom of a web page, that contains information that improves overall usability. Built with Tailwind CSS by WindUI."
        ogdescription="Footer component, is the section at the very bottom of a web page, that contains information that improves overall usability. Built with Tailwind CSS by WindUI."
        url="components/footers/"
        ogimage={ogImage}
        author="Wind UI"
        keywords="Tailwind CSS, Tailwind CSS Footers, Footers, Footer Components, WindUI, windui, wind ui"
        robots="index, follow"
        revisit_after="7 days"
      />
      <div className="min-w-0 flex-1">
        {/* Hero Section */}
        <Section ariaLabelledby="intro-heading" id="intro">
          <h1 id="intro-heading">Footers</h1>
          <p>
            The website footer is the section of content at the very bottom of a
            web page. It typically contains a copyright notice, link to a
            privacy policy, sitemap, logo, contact information, social media
            icons, and an email sign-up form. In short, a footer contains
            information that improves a website's overall usability.
          </p>

          <div className="w-full overflow-hidden rounded-xl bg-white pt-20 shadow-xl shadow-wuiSlate-200 ring-1 ring-wuiSlate-200">
            <section
              className="not-prose max-w-full"
              aria-multiselectable="false"
            >
              <FooterHeroComponent />
            </section>
          </div>
        </Section>

        {/* Variations Section*/}
        <Section ariaLabelledby="variations-heading" id="variations-section">
          <h2 id="variations-heading">Variations</h2>

          {/* Three Columns */}
          <h3 id="FooterThreeCols">Three Columns</h3>
          <PreviewBlock
            id="FooterThreeCols"
            HtmlComponent={FooterThreeColsHTML.default}
            JsxComponent={FooterThreeColsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterThreeColsPreview
                FooterThreeCols={
                  activeTabs[0].active_tab === 1
                    ? FooterThreeColsHTML.default
                    : FooterThreeColsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Four Columns*/}
          <h3 id="FooterFourCols">Four Columns</h3>
          <PreviewBlock
            id="FooterFourCols"
            HtmlComponent={FooterFourColsHTML.default}
            JsxComponent={FooterFourColsJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterFourColsPreview
                FooterFourCols={
                  activeTabs[1].active_tab === 1
                    ? FooterFourColsHTML.default
                    : FooterFourColsJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Three Columns with Sub Footer*/}
          <h3 id="FooterThreeColsSubFooter">Three Columns with Sub Footer</h3>
          <PreviewBlock
            id="FooterThreeColsSubFooter"
            HtmlComponent={FooterThreeColsSubFooterHTML.default}
            JsxComponent={FooterThreeColsSubFooterJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterThreeColsSubFooterPreview
                FooterThreeColsSubFooter={
                  activeTabs[2].active_tab === 1
                    ? FooterThreeColsSubFooterHTML.default
                    : FooterThreeColsSubFooterJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Four Columns with Sub Footer*/}
          <h3 id="FooterFourColsSubFooter">Four Columns with Sub Footer</h3>
          <PreviewBlock
            id="FooterFourColsSubFooter"
            HtmlComponent={FooterFourColsSubFooterHTML.default}
            JsxComponent={FooterFourColsSubFooterJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterFourColsSubFooterPreview
                FooterThreeColsSubFooter={
                  activeTabs[3].active_tab === 1
                    ? FooterFourColsSubFooterHTML.default
                    : FooterFourColsSubFooterJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Five Columns with Logo */}
          <h3 id="FooterFiveColsLogo">Five Columns with Logo </h3>
          <PreviewBlock
            id="FooterFiveColsLogo"
            HtmlComponent={FooterFiveColsLogoHTML.default}
            JsxComponent={FooterFiveColsLogoJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterFiveColsLogoPreview
                FooterFiveColsLogo={
                  activeTabs[4].active_tab === 1
                    ? FooterFiveColsLogoHTML.default
                    : FooterFiveColsLogoJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Five Columns with Logo and Sub Footer */}
          <h3 id="FooterFiveColsLogoSubFooter">
            Five Columns with Logo and Sub Footer
          </h3>
          <PreviewBlock
            id="FooterFiveColsLogoSubFooter"
            HtmlComponent={FooterFiveColsLogoSubFooterHTML.default}
            JsxComponent={FooterFiveColsLogoSubFooterJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterFiveColsLogoSubFooterPreview
                FooterFiveColsLogoSubFooter={
                  activeTabs[5].active_tab === 1
                    ? FooterFiveColsLogoSubFooterHTML.default
                    : FooterFiveColsLogoSubFooterJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Dark Theme */}
          <h3 id="FooterDarkTheme">Dark Theme</h3>
          <PreviewBlock
            id="FooterDarkTheme"
            HtmlComponent={FooterDarkThemeHTML.default}
            JsxComponent={FooterDarkThemeJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterDarkThemePreview
                FooterDarkTheme={
                  activeTabs[6].active_tab === 1
                    ? FooterDarkThemeHTML.default
                    : FooterDarkThemeJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Primary Dark Theme */}
          <h3 id="FooterPrimaryDarkTheme">Primary Dark Theme</h3>
          <PreviewBlock
            id="FooterPrimaryDarkTheme"
            HtmlComponent={FooterPrimaryDarkThemeHTML.default}
            JsxComponent={FooterPrimaryDarkThemeJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterPrimaryDarkThemePreview
                FooterPrimaryDarkTheme={
                  activeTabs[7].active_tab === 1
                    ? FooterPrimaryDarkThemeHTML.default
                    : FooterPrimaryDarkThemeJsx.default
                }
              />
            </div>
          </PreviewBlock>

          {/* Primary Light Theme */}
          <h3 id="FooterPrimaryLightTheme">Primary Light Theme</h3>
          <PreviewBlock
            id="FooterPrimaryLightTheme"
            HtmlComponent={FooterPrimaryLightThemeHTML.default}
            JsxComponent={FooterPrimaryLightThemeJsx.default}
            getActiveTabs={getActiveTabs}
          >
            <div
              ref={wrapperRef}
              className="w-full overflow-hidden rounded-xl bg-white pt-20"
            >
              <FooterPrimaryLightThemePreview
                FooterPrimaryLightTheme={
                  activeTabs[8].active_tab === 1
                    ? FooterPrimaryLightThemeHTML.default
                    : FooterPrimaryLightThemeJsx.default
                }
              />
            </div>
          </PreviewBlock>
        </Section>

        {/* Usage Section*/}
        <Section ariaLabelledby="usage-heading" id="usage-section">
          <h2 id="usage-heading">Usage</h2>
          <p>
            The website footer is the section of content at the very bottom of a
            web app. Use the footer to add:
          </p>
          <ul>
            <li>Links to other sections of your app</li>
            <li>links to Privacy Policy, Terms & conditions etc.</li>
            <li>Logo, about text or even contact information</li>
            <li>Social media icons</li>
          </ul>
        </Section>
        {/* Specs Section */}
        <Section ariaLabelledby="specs-heading" id="specs">
          <h2 id="specs-heading">Specs</h2>
          <p>
            The footer is using the native <code>&lt;footer&gt;</code> element.
            It consists of 2 main parts:
          </p>
          <ul>
            <li>
              <strong>Main footer: </strong> it consists of columns that can
              contain navigation links, about us text, brand and contact
              information. Text size is 0.875rem (14px) and 1rem (16px) for the
              navigation column headers.
            </li>
            <li>
              <strong>Sub footer: </strong> you can add secondary links (like
              links to Terms and Conditions, Privacy policy etc), social links
              or contact information.
            </li>
          </ul>
        </Section>
        {/* Accessibility Notes Section */}
        <Section
          ariaLabelledby="accessibility-notes-heading"
          id="accessibility-notes"
        >
          <h2 id="accessibility-notes-heading">Accessibility notes</h2>
          <ul>
            <li>
              Footer is implemented using the native <code>&lt;footer&gt;</code>{" "}
              html tag.{" "}
            </li>
            <li>
              All navigation columns should use the <code>&lt;nav&gt;</code>{" "}
              tag. If you have to use a more generic element such as a{" "}
              <code>&lt;div&gt;</code> add a{" "}
              <code>role=&quot;navigation&quot;</code> to every navbar column to
              explicitly identify it as a landmark region for users of assistive
              technologies.
            </li>
            <li>
              Menus should be labelled according to their individual function.
              Use an <code>aria-labelledby=&quot;&quot;</code> to easily add
              context to the different navigation columns.
            </li>
          </ul>
        </Section>
      </div>

      <ContentNavigation variations={activeTabs} />
    </SidebarLayout>
  )
}

export default FootersPage
